<ng-container *ngFor="let reaction of $reactions()">
  <app-reaction-button
    [reaction]="reaction"
    [tooltipReactionText]="reactionService.$tooltipReactionText()"
    [tooltipEnabled]="settings?.showReactionsTooltips ?? true"
    [showQuantity]="settings?.showReactionsQuantity ?? true"
    (hoverButton)="onMouseEnter($event)"
    (emojiClicked)="onClick($event)"
  />
</ng-container>
<ng-content></ng-content>
