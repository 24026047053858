import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, Output, Signal, computed, inject } from '@angular/core';
import { Reaction } from 'src/app/shared/reactions/reaction';
import { ReactionButtonComponent } from 'src/app/shared/reactions/reaction-button/reaction-button.component';
import { ReactionService } from 'src/app/shared/reactions/reaction.service';
import { ThrottledObservableGeneratorData, throttledObservableGenerator } from 'src/app/shared/utils/throttled-obsevable-generator';

export interface ReactionListSettings {
  showReactionsTooltips: boolean;
  showReactionsQuantity: boolean;
  fixedEmojiList: ('👏' | '👍' | '🥳' | '❤️')[];
}

@Component({
  selector: 'app-reaction-list',
  templateUrl: './reaction-list.component.html',
  styleUrls: ['./reaction-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactionButtonComponent],
})
export class ReactionListComponent {
  @Input() public settings: ReactionListSettings | undefined;
  @Output() public emojiClicked = new EventEmitter<string>();

  public $reactions: Signal<Reaction[]> = computed(() =>
    this.settings?.fixedEmojiList?.length > 0
      ? this.settings.fixedEmojiList.map((emoji) => ({ emoji, quantity: 0 }))
      : this.reactionService.$reactions(),
  );

  private executeLoadEmojis: ThrottledObservableGeneratorData<string>;

  constructor(public reactionService: ReactionService) {
    const destroyRef = inject(DestroyRef);

    this.executeLoadEmojis = throttledObservableGenerator<string>((emoji) => this.reactionService.loadEmojiReactions(emoji), destroyRef);
  }

  public onMouseEnter(reaction: Reaction): void {
    if (this.settings?.showReactionsTooltips ?? true) {
      this.executeLoadEmojis.executionSubject$.next(reaction.emoji);
    }
  }

  public onClick(reaction: Reaction): void {
    this.emojiClicked.emit(reaction.emoji);
  }
}
